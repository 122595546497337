import React from 'react';
import "./HomePage.css"
function HomePage() {


  return (
    <div className="join_page">
      <main>
        <img width={150} src='https://cdn2.mistercoding.com/mister_0efb398dc8.png' />
        <h1>Mistercoding Live Session</h1>
        <h2>Bootcamp, Live Class, Mentoring</h2>
      </main>
    </div>
  );
}

export default HomePage;
