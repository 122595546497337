import React, { useEffect } from 'react';

import './JoinPage.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import { useSearchParams } from 'react-router-dom';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');


function JoinPage() {

  const [param] = useSearchParams();

  const meeting_number = param.get("meeting_number")
  const meeting_password = param.get("meeting_password")
  const meeting_username = param.get("meeting_username")
  const meeting_email = param.get("meeting_email")
  const meeting_role = param.get("meeting_role")
  const meeting_signature = param.get("meeting_signature")
  // const access_token = param.get("access_token")

  useEffect(() => {

    console.log("abc", param)
  }, [param])

  const authEndpoint = ''
  const sdkKey = '5hdI9M1uRNucf9AhR5UqQ'
  const meetingNumber = meeting_number
  const passWord = meeting_password
  const userName = meeting_username
  const userEmail = meeting_email
  const leaveUrl = 'https://zoom.mistercoding.com'

  function getSignature(e) {
    e.preventDefault();

    startMeeting(meeting_signature)
  }

  useEffect(() => {
    // console.log("abc", meetingNumber);
  }, [meetingNumber])
  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log("abc", meetingNumber, passWord, userName, userEmail)

        ZoomMtg.join({
          sdkKey: sdkKey,
          signature: signature,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          success: (success) => {
            console.log("join success",success)

          },
          error: (error) => {
            console.log("join error",error)
          }
        });
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="join_page">
      <main>
        <img width={150} src='https://cdn2.mistercoding.com/mister_0efb398dc8.png' />
        <h1>Mistercoding Live Session</h1>
        <button onClick={getSignature}>Join Session</button>
      </main>
    </div>
  );
}

export default JoinPage;
